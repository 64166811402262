<template>
  <div>
    <div
      class="page-header min-vh-75"
      :style="`background-image: url(${headerImg})`"
      loading="lazy"
    >
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-white text-center"></div>
        </div>
      </div>
    </div>
    <div class="page-header py-5">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-7 col-md-9 d-flex justify-content-center flex-column py-9"
          >
            <h2 class="display-3 fw-bold">
              Let's talk about your financial future.
            </h2>
            <h3 class="text-muted">
              Send us a message and we'll take care of the rest.
            </h3>
            <EmailForm />
          </div>
          <div class="col-lg-5 col-md-3 position-relative ms-auto">
            <div class="blur-shadow-image">
              <img
                class="h-100 border-radius-xl position-absolute d-md-block d-none"
                src="@/assets/images/contact_us.jpg"
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmailForm from "@/components/EmailForm.vue";
export default {
  name: "Contact",
  components: {
    EmailForm,
  },
  computed: {},
  data() {
    return {
      headerImg: require("@/assets/images/play_smart_office.jpg"),
      contactImg: require("@/assets/images/contact_us.jpg"),
    };
  },
  metaInfo: {
    title: "Play Smart",
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <div>
    <div
      class="page-header min-vh-75"
      :style="`background-image: url(${headerImg})`"
      loading="lazy"
    >
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-white text-center">
            <h1
              class="text-white animate__animated animate__fadeIn animate__slow"
            >
              {{ $t("about.title") }}
            </h1>
            <p
              class="lead animate__animated animate__fadeIn animate__delay-1s animate__slow"
            >
              {{ $t("about.lead") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body shadow-xl mx-3 mx-md-4 mt-n6 mb-5">
      <div class="container clearfix mt-5">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('about.intro')"></div>
        </div>
      </div>
      <section
        v-animate-onscroll="'animate__animated animate__slideInUp '"
        class="my-5 py-5 bg-gradient-dark position-relative overflow-hidden mx-n3"
      >
        <img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute start-0 top-0 w-100 opacity-1"
        />
        <div class="container position-relative z-index-2">
          <div class="row">
            <div class="col-lg-8 col-md-12 m-auto text-start">
              <h4
                class="text-lg-center text-center mt-2 mb-0 text-white fw-bold text-start"
                v-html="$t('about.ceo')"
              ></h4>
            </div>
          </div>
        </div>
      </section>
      <div class="container clearfix">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('about.partnersIntro')"></div>
        </div>
        <div class="row d-flex justify-content-center mt-5 mb-3">
          <div class="col-lg-10">
            <p class="text-center">{{ $t("about.partnersText") }}</p>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-5">
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://olympics.com/ioc/overview"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/mok.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://olympics.com/ioc/olympic-solidarity"
              target="_blank"
              class="border-0"
            >
              <img
                src="@/assets/images/partners/solidarity.jpg"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a href="https://olympic.si" target="_blank" class="border-0"
              ><img
                src="@/assets/images/partners/oks.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://comiteolimpicoportugal.pt/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/portugal.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.olympia.at/main.asp?kat1=85&kat2=603"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/austria.jpg"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.ltok.lt/en/homepage/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/litva.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a href="https://oks.org.rs/" target="_blank" class="border-0"
              ><img
                src="@/assets/images/partners/srbija.jpg"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a href="https://www.hoo.hr/en" target="_blank" class="border-0">
              <img
                src="@/assets/images/partners/croatia.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.eoaolympic.org/"
              target="_blank"
              class="border-0"
            >
              <img
                src="@/assets/images/partners/eu_akademije.jpeg"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
        </div>
        <hr class="divider" />
        <div class="row d-flex justify-content-center mb-5">
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a href="https://www.napier.ac.uk/" target="_blank" class="border-0"
              ><img
                src="@/assets/images/partners/edinburg.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.amsterdamuas.com/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/amsterdam.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a href="https://www.unito.it/" target="_blank" class="border-0"
              ><img
                src="@/assets/images/partners/torino.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://international.au.dk/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/aarhus.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>

          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.fh-joanneum.at/en/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/fh.jpg"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>

          <div class="col-lg-2 col-md-4 col-sm-6 align-self-center h-100">
            <a
              href="https://www.wus-austria.org/"
              target="_blank"
              class="border-0"
              ><img
                src="@/assets/images/partners/wus.png"
                class="img-fluid"
                v-animate-onscroll="
                  'animate__animated animate__flipInX animate__slow'
                "
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  components: {},
  computed: {},
  data() {
    return {
      headerImg: require("@/assets/images/about_play_smart.jpg"),
    };
  },
  metaInfo: {
    title: "About Play Smart",
  },
  methods: {},
  mounted() {},
};
</script>

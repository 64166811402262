<template>
  <div>
    <header class="position-relative">
      <div
        class="page-header min-vh-100 position-relative"
        style="background: #000"
      >
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          loading="lazy"
          poster="@/assets/images/video_cover.png"
        >
          <source src="@/assets/video/play_smart_bg.mp4" type="video/mp4" />
        </video>
        <span class="mask mask-dark opacity-3 bg-gradient-info"></span>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center mx-auto mt-n7">
              <h1
                class="text-white animate__animated animate__fadeIn animate__delay-1s animate__slow"
              >
                {{ $t("home.title") }}
              </h1>
              <h4
                class="text-light animate__animated animate__fadeIn animate__delay-3s mb-2"
              >
                {{ $t("home.subTitle") }}
              </h4>
              <router-link
                :to="{ name: 'About' }"
                class="btn bg-white me-2 animate__animated animate__fadeIn animate__delay-4s mt-5"
              >
                {{ $t("btn.learnMore") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </header>
    <section class="header-rounded-images my-8">
      <div class="page-header min-vh-90">
        <img
          class="position-absolute fixed-top ms-auto w-50 h-100 z-index-0 d-none d-sm-none d-md-block border-radius-section border-top-end-radius-0 border-top-start-radius-90 border-bottom-end-radius-0"
          src="@/assets/images/quote_1.jpg"
          alt="image"
          loading="lazy"
          v-animate-onscroll="
            'animate__animated animate__fadeIn animate__slower'
          "
        />
        <div class="container">
          <div class="row">
            <div class="col-lg-7 d-flex">
              <div
                class="card card-body blur text-md-start text-center px-sm-5 shadow-lg mt-sm-5 py-sm-5"
                v-animate-onscroll="'animate__animated animate__flipInY '"
              >
                <h2 class="text-dark mb-4" v-html="$t('home.uniqueTitle')"></h2>

                <p class="lead text-dark pe-md-5 me-md-5">
                  {{ $t("home.uniqueText") }}
                </p>
                <div class="buttons">
                  <router-link
                    class="btn bg-gradient-primary mt-4"
                    :to="{ name: 'Contact' }"
                  >
                    {{ $t("btn.contactUs") }}
                  </router-link>
                  <router-link
                    class="btn btn-outline-secondary mt-4 ms-2"
                    :to="{ name: 'AthleteChallenges' }"
                  >
                    {{ $t("btn.readMore") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      class="mt-5 mb-9 py-9 bg-gradient-info position-relative overflow-hidden mx-n3 shadow-dark"
      v-animate-onscroll="'animate__animated animate__fadeIn'"
    >
      <img
        src="@/assets/img/shapes/pattern-lines.svg"
        alt="pattern-lines"
        class="position-absolute start-0 top-0 w-100 opacity-9"
      />
      <div
        class="container position-relative z-index-2"
        v-animate-onscroll="'animate__animated animate__flipInY '"
      >
        <div class="row">
          <div class="col-lg-6 col-md-8 m-auto text-start">
            <h2
              class="bg-white rounded p-2 text-black mb-lg-0 mb-2"
              v-html="$t('home.smartTitle')"
            ></h2>
            <p
              class="text-lg-start text-sm-start mt-2 mb-0 text-white fw-bold text-start"
            >
              {{ $t("home.smartText") }}
            </p>
            <router-link
              :to="{ name: 'Solutions' }"
              class="btn bg-gradient-light mt-3 me-auto"
            >
              {{ $t("btn.learnMore") }}
            </router-link>
            <router-link
              class="btn btn-outline-secondary mt-4 ms-2"
              :to="{ name: 'AthleteChallenges' }"
            >
              {{ $t("menu.about") }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="header-rounded-images mt-0 mb-5">
      <div class="page-header min-vh-90">
        <img
          class="position-absolute fixed-top me-auto w-65 h-100 z-index-0 d-none d-sm-none d-md-block border-radius-section border-top-start-radius-0 border-top-end-radius-90 border-bottom-end-radius-90 border-bottom-start-radius-0"
          src="@/assets/images/smart_approach_2.jpg"
          alt="image"
          loading="lazy"
          v-animate-onscroll="
            'animate__animated animate__fadeIn animate__slower'
          "
        />
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-lg-7 d-flex">
              <div
                class="card card-body blur text-md-start text-center px-sm-5 shadow-lg mt-sm-5 py-sm-5"
                v-animate-onscroll="'animate__animated animate__flipInY '"
              >
                <h2 class="text-dark mb-4" v-html="$t('home.whomTitle')"></h2>

                <p
                  class="lead text-dark pe-md-5 me-md-5"
                  v-html="$t('home.whomText')"
                ></p>
                <div class="buttons">
                  <router-link
                    class="btn bg-gradient-info mt-4"
                    :to="{ name: 'Contact' }"
                  >
                    {{ $t("btn.contactUs") }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {},
  computed: {},
};
</script>

import Vue from "vue";
import VueI18n from "vue-i18n";
import eng from "./content/english.json";
import slo from "./content/slovenian.json";

export const defaultLocale = "en";

export const languages = {
  en: "en",
  sl: "sl",
};

Vue.use(VueI18n);

const messages = {
  en: eng,
  sl: slo,
};

/* eslint-disable no-unused-vars */
const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "sl", // set fallback locale
  messages, // set locale messages
});
/* eslint-enable no-unused-vars */

export default i18n;

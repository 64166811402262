<template>
  <div>
    <div
      class="page-header min-vh-75"
      :style="`background-image: url(${headerImg})`"
      loading="lazy"
    >
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-white text-center">
            <h1
              class="text-white animate__animated animate__fadeIn animate__slow"
            >
              {{ $t("solutions.title") }}
            </h1>
            <p
              class="lead animate__animated animate__fadeIn animate__delay-1s animate__slow"
            >
              {{ $t("solutions.lead") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body shadow-xl mx-3 mx-md-4 mt-n6 mb-5">
      <div class="container clearfix mt-5">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('solutions.intro')"></div>
        </div>
      </div>
      <section
        class="my-5 py-5 bg-gradient-dark position-relative overflow-hidden mx-n3"
        v-animate-onscroll.repeat="'animate__animated animate__fadeIn '"
      >
        <img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute start-0 top-0 w-100 opacity-1"
        />
        <div
          class="container position-relative z-index-2"
          v-animate-onscroll.repeat="'animate__animated animate__flipInY '"
        >
          <div class="row">
            <div class="col-lg-8 col-md-12 m-auto text-start">
              <h4
                class="text-start mt-2 mb-0 text-white fw-bold text-start"
                v-html="$t('solutions.proprietary')"
              ></h4>
            </div>
          </div>
        </div>
      </section>
      <div class="container clearfix">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('solutions.servicesIntro')"></div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-5 align-self-center">
            <ul class="list-group list-group-flush mt-3 mb-5">
              <li
                class="list-group-item"
                v-for="(service, i) in $t('solutions.services')"
                :key="`service${i}`"
                v-animate-onscroll.repeat="'animate__animated animate__zoomIn '"
              >
                <font-awesome-icon
                  icon="check"
                  class="me-3 text-muted"
                  size="sm"
                /><strong>{{ service }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Solutions",
  components: {},
  computed: {},
  data() {
    return {
      headerImg: require("@/assets/images/solutions_3.jpg"),
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Play Smart solutions for professional athletes",
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <div id="app">
    <Header />
    <router-view />
    <footer id="footer" class="py-5 text-light bg-gradient-dark">
      <div class="container">
        <div class="row col">
          <div class="col-md-6 text-center text-md-start">
            {{ $t("footer.copy") }}
            <br /><br />
          </div>

          <div class="col-md-6 text-center text-md-end">
            <div class="copyright-links">
              <a href="#" class="text-light me-3">{{ $t("footer.terms") }}</a>
              <a href="#" class="text-light">{{ $t("footer.privacy") }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #copyrights end -->
    </footer>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import "@/assets/css/nucleo-icons.css";
import "@/assets/css/material-kit-pro.css";
import "@/assets/css/custom.css";
import "@/assets/css/style.css";
import "animate.css";

export default {
  name: "App",
  components: { Header },
  computed: {},

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Play Smart",
    // all titles will be injected into this template
    titleTemplate:
      "%s | Financial wellness & education for professional athletes",
  },
  methods: {},
  mounted() {},
};
</script>

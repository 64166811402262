<template>
  <div class="container position-sticky z-index-sticky top-0">
    <div class="row">
      <div class="col-12">
        <nav
          class="navbar navbar-expand-lg blur border-radius-xl position-absolute my-3 top-0 border-bottom py-3 z-index-3 shadow my-3 py-2 start-0 end-0 mx-4"
        >
          <div class="container">
            <router-link
              class="navbar-brand"
              :to="{ name: 'Home' }"
              rel="tooltip"
              title="Play Smart"
              data-placement="bottom"
            >
              <img
                src="@/assets/logo/play-smart_logo_dark.svg"
                class="d-none d-md-block"
                style="height: 30px"
              />
              <img
                src="@/assets/logo/play-smart_logo_dark.svg"
                class="d-sm-block d-md-none"
                style="height: 18px"
              />
            </router-link>

            <button
              class="navbar-toggler shadow-none ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navigation"
              aria-controls="navigation"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon mt-2">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
              </span>
            </button>
            <div
              class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0"
              id="navigation"
            >
              <ul class="navbar-nav navbar-nav-hover mx-auto">
                <li class="nav-item mx-2">
                  <router-link
                    class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                    :to="{ name: 'AthleteChallenges' }"
                  >
                    <span
                      class="d-block w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#navigation"
                      >{{ $t("menu.challenges") }}</span
                    >
                  </router-link>
                </li>

                <li class="nav-item mx-2">
                  <router-link
                    class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                    :to="{ name: 'Solutions' }"
                  >
                    <span
                      class="d-block w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#navigation"
                    >
                      {{ $t("menu.solutions") }}</span
                    >
                  </router-link>
                </li>
                <li class="nav-item mx-2">
                  <router-link
                    class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                    :to="{ name: 'About' }"
                  >
                    <span
                      class="d-block w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#navigation"
                      >{{ $t("menu.about") }}</span
                    >
                  </router-link>
                </li>
                <li class="nav-item mx-2">
                  <router-link
                    class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                    :to="{ name: 'Book' }"
                  >
                    <span
                      class="d-block w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#navigation"
                      >{{ $t("menu.book") }}</span
                    >
                  </router-link>
                </li>

                <li class="nav-item mx-2">
                  <router-link
                    class="nav-link ps-2 d-flex justify-content-between cursor-pointer align-items-center"
                    :to="{ name: 'Contact' }"
                  >
                    <span
                      class="d-block w-100"
                      data-bs-toggle="collapse"
                      data-bs-target="#navigation"
                      >{{ $t("menu.contact") }}</span
                    >
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {},
};
</script>

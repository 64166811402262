import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// axios
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

// form validation
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// bs5
import "bootstrap";

// multilanguage
import i18n from "@/plugins/i18n";

// meta data
import VueMeta from "vue-meta";
Vue.use(VueMeta);

// fontAwesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, fab);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// animate on scroll
import VueAnimateOnScroll from "vue-animate-onscroll";
Vue.use(VueAnimateOnScroll);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import AthleteChallenges from "../views/AthleteChallenges.vue";
import Solutions from "../views/Solutions.vue";
import About from "../views/About.vue";
import Book from "../views/Book.vue";
import Contact from "../views/Contact.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/athlete_challenges",
    name: "AthleteChallenges",
    component: AthleteChallenges,
  },
  {
    path: "/solutions",
    name: "Solutions",
    component: Solutions,
  },
  {
    path: "/about_play_smart",
    name: "About",
    component: About,
  },
  {
    path: "/the_winning_mindset",
    name: "Book",
    component: Book,
  },
  {
    path: "/contact_us",
    name: "Contact",
    component: Contact,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;

<template>
  <div>
    <div
      class="page-header min-vh-75"
      :style="`background-image: url(${headerImg})`"
      loading="lazy"
    >
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-white text-center">
            <h1
              class="text-white animate__animated animate__fadeIn animate__slow"
            >
              {{ $t("challenges.title") }}
            </h1>
            <p
              class="lead animate__animated animate__fadeIn animate__delay-1s animate__slow"
            >
              {{ $t("challenges.lead") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body shadow-xl mx-3 mx-md-4 mt-n6 mb-5">
      <div class="container clearfix mt-5">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10">
            <p v-html="$t('challenges.researchIntro')">
              {{ $t("challenges.researchIntro") }}
            </p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6 mb-md-0 mb-3">
            <div
              class="card card-background card-background-mask-dark mt-3 h-100 shadow-primary text-white"
              v-animate-onscroll="'animate__animated animate__zoomIn '"
            >
              <div
                class="full-background"
                :style="`background-image: url(${nbaImg})`"
              ></div>
              <div class="card-body p-5 text-center my-auto">
                <span class="text-light-color">Estimated</span>
                <h3 class="text-gradient text-primary display-2 fw-bold">
                  <countTo :startVal="0" :endVal="60" :duration="4000" />%
                </h3>
                <h5 class="mt-3 text-white">
                  of former NBA players are struggling or broke.
                </h5>
                <p class="text-light-color mt-3">
                  Within five years of retirement.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="card card-background card-background-mask-dark mt-3 h-100 shadow-primary text-white"
              v-animate-onscroll="'animate__animated animate__zoomIn '"
            >
              <div
                class="full-background"
                :style="`background-image: url(${nflImg})`"
              ></div>
              <div class="card-body p-5 text-center my-auto">
                <span class="text-light-color">Estimated</span>
                <h3 class="text-primary display-2 fw-bold">
                  <countTo :startVal="0" :endVal="78" :duration="5000" />%
                </h3>
                <h5 class="mt-3 text-white">
                  of former NFL players have gone bankrupt or are under
                  financial stress.
                </h5>
                <p class="text-light-color mt-3">
                  By the time they have been retired for two years.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-lg-10 mt-5">
            <p v-html="$t('challenges.uefaIntro')"></p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-md-6">
            <div
              class="card card-background card-background-mask-dark mt-3 h-100 shadow-primary text-white"
              v-animate-onscroll="'animate__animated animate__slideInUp '"
            >
              <div
                class="full-background"
                :style="`background-image: url(${uefaImg})`"
              ></div>
              <div class="card-body p-5 text-center my-auto">
                <span class="text-light-color">Estimated</span>
                <h3 class="text-gradient text-primary display-2 fw-bold">
                  <countTo :startVal="0" :endVal="80" :duration="6000" />%
                </h3>
                <h5 class="mt-3 text-white">
                  of players go bankrupt after the end of their football career.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section
        class="mb-5 mt-7 py-9 bg-gradient-dark position-relative overflow-hidden mx-n3"
        v-animate-onscroll="'animate__animated animate__slideInUp '"
      >
        <img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute start-0 top-0 w-100 opacity-1"
        />
        <div class="container position-relative z-index-2">
          <div class="row">
            <div class="col-lg-8 col-md-12 m-auto text-start">
              <h3
                class="text-lg-center text-center mt-2 mb-0 text-white fw-bold text-start"
              >
                {{ $t("challenges.sweeping") }}
              </h3>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('challenges.researchText')"></div>
        </div>
        <div class="row d-flex justify-content-between mt-3">
          <div class="col-lg-7 align-self-center">
            <h2
              class="my-4"
              v-animate-onscroll="'animate__animated animate__zoomIn '"
            >
              {{ $t("challenges.ask") }}
            </h2>
            <ul class="list-group list-group-flush">
              <li
                class="list-group-item"
                v-for="(question, i) in $t('challenges.questions')"
                :key="`question${i}`"
                v-animate-onscroll.repeat="'animate__animated animate__zoomIn '"
              >
                <font-awesome-icon
                  icon="angle-right"
                  class="me-3 text-muted"
                  size="sm"
                />{{ question }}
              </li>
            </ul>
            <div class="my-5 fw-bold display-2">
              <blockquote>{{ $t("challenges.knowAnswers") }}</blockquote>
            </div>
          </div>
          <div class="col-lg-4 hidden-sm hidden-md align-self-center">
            <img
              src="@/assets/images/success.jpg"
              class="img-fluid rounded shadow"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";

export default {
  name: "AthleteChallenges",
  components: { countTo },
  computed: {},
  data() {
    return {
      headerImg: require("@/assets/images/challenges_2.jpg"),
      nbaImg: require("@/assets/images/nba.jpg"),
      nflImg: require("@/assets/images/nfl2.jpg"),
      uefaImg: require("@/assets/images/uefa.jpeg"),
    };
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Challenges of professional athletes",
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <div>
    <div
      class="page-header min-vh-75"
      :style="`background-image: url(${headerImg})`"
      loading="lazy"
    >
      <span class="mask bg-gradient-dark opacity-4"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-white text-center">
            <h1
              class="text-white animate__animated animate__fadeIn animate__slow"
            >
              {{ $t("book.title") }}
            </h1>
            <p
              class="lead animate__animated animate__fadeIn animate__delay-1s animate__slow"
            >
              {{ $t("book.lead") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-body shadow-xl mx-3 mx-md-4 mt-n6 mb-5">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-8 text-center my-5">
          <img
            src="@/assets/images/winning_mindset_book.png"
            class="img-fluid shadow"
          />
        </div>
      </div>
      <section
        class="my-5 py-5 bg-gradient-dark position-relative overflow-hidden mx-n3"
        v-animate-onscroll="'animate__animated animate__fadeIn '"
      >
        <img
          src="@/assets/img/shapes/waves-white.svg"
          alt="pattern-lines"
          class="position-absolute start-0 top-0 w-100 opacity-1"
        />
        <div class="container position-relative z-index-2">
          <div class="row">
            <div class="col-lg-8 col-md-12 m-auto text-start">
              <h4
                class="text-lg-center text-center mt-2 mb-0 text-white fw-bold text-start"
                v-html="$t('book.assistance')"
              ></h4>
            </div>
          </div>
        </div>
      </section>
      <div class="container clearfix">
        <div class="row d-flex justify-content-center">
          <div class="col-lg-10" v-html="$t('book.guidelinesIntro')"></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-6 align-self-center">
            <ul class="list-group list-group-flush mt-3 mb-5">
              <li
                class="list-group-item"
                v-for="(guideline, i) in $t('book.guidelines')"
                :key="`guideline${i}`"
                v-animate-onscroll="'animate__animated animate__slideInUp '"
              >
                <font-awesome-icon
                  icon="check"
                  class="me-3 text-muted"
                  size="sm"
                />{{ guideline }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-5">
          <div class="col-lg-10">
            <h4>{{ $t("book.youngProfessional") }}</h4>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8">
            <p class="text-center">{{ $t("book.foreWordIntro") }}</p>
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8 align-self-center">
            <ul class="list-group list-group-flush mt-3 mb-5">
              <li
                class="list-group-item text-center"
                v-for="(fw, i) in $t('book.foreWords')"
                :key="`fw${i}`"
              >
                <div
                  v-html="fw"
                  v-animate-onscroll="'animate__animated animate__slideInUp '"
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-5">
          <div class="col-lg-8 col-md-12 align-self-center">
            <blockquote
              class="fw-bold"
              v-animate-onscroll="
                'animate__animated animate__lightSpeedInLeft '
              "
            >
              <i>{{ $t("book.authorQuote") }}</i>
            </blockquote>
            <p
              class="ms-3"
              v-html="$t('book.author')"
              v-animate-onscroll="
                'animate__animated animate__lightSpeedInRight '
              "
            ></p>
          </div>
          <div class="col-lg-3 col-md-6 align-self-end">
            <img
              src="@/assets/images/mihaurek.jpg"
              class="img-fluid shadow rounded"
              v-animate-onscroll="
                'animate__animated animate__fadeIn animate__slower'
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Book",
  components: {},
  computed: {},
  data() {
    return {
      headerImg: require("@/assets/images/winning_mindset.png"),
    };
  },
  metaInfo: {
    title: "The Winning Mindset book",
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <div class="col-auto mx-auto d-flex justify-content-center flex-column mt-5">
    <form id="contact-form" autocomplete="off" @submit.prevent="sendForm">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="input-group input-group-static mb-4">
              <label>First name</label>
              <input
                class="form-control"
                aria-label="First Name..."
                type="text"
                placeholder="First name ..."
                v-model.trim="$v.form.name.$model"
                :disabled="submitStatus === 'OK'"
              />
              <div class="text-primary" v-if="$v.form.name.$invalid && sent">
                <small>Field is required</small>
              </div>
            </div>
          </div>
          <div class="col-md-6 ps-2">
            <div
              :class="{ 'form-group--error': $v.form.surname.$error }"
              class="form-group input-group input-group-static"
            >
              <label>Last name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Last name ..."
                aria-label="Last Name..."
                v-model.trim="$v.form.surname.$model"
                :disabled="submitStatus === 'OK'"
              />
              <div class="text-primary" v-if="$v.form.surname.$invalid && sent">
                <small>Field is required</small>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div
            :class="{ 'form-group--error': $v.form.email.$error }"
            class="form-group input-group input-group-static"
          >
            <label>Email</label>
            <input
              type="email"
              class="form-control"
              v-model="form.email"
              placeholder="Your email ..."
              :disabled="submitStatus === 'OK'"
            />
            <div class="text-primary" v-if="$v.form.email.$invalid && sent">
              <small>Must be a valid email address.</small>
            </div>
          </div>
        </div>
        <div
          :class="{ 'form-group--error': $v.form.message.$error }"
          class="form-group input-group mb-4 input-group-static"
        >
          <label>Your message</label>
          <textarea
            name="message"
            class="form-control"
            id="message"
            rows="4"
            v-model="form.message"
            :disabled="submitStatus === 'OK'"
          ></textarea>
          <div class="text-primary" v-if="$v.form.message.$invalid && sent">
            <small
              >Message must be at least
              {{ $v.form.message.$params.minLength.min }} characters
              long.</small
            >
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div
              :class="{ 'form-group--error': $v.form.checked.$error }"
              class="form-group form-check form-switch mb-4 d-flex align-items-center"
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                :checked="form.checked"
                v-model="form.checked"
                :disabled="submitStatus === 'OK'"
              />
              <label
                class="form-check-label ms-3 mb-0"
                for="flexSwitchCheckDefault"
                >I agree to the
                <a href="javascript:;" class="text-dark"
                  ><u>Terms and Conditions</u></a
                >.</label
              >
              <div class="text-primary" v-if="$v.form.email.$invalid && sent">
                <small>Must agree to terms & conditions.</small>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div v-if="!confirmation">
              <button
                class="btn bg-gradient-dark w-100"
                type="submit"
                :disabled="submitStatus === 'OK'"
              >
                Send Message
              </button>
            </div>
            <div v-else>
              <div
                class="alert alert-info alert-dismissible fade show"
                role="alert"
              >
                <span class="alert-icon me-2 text-white"
                  ><font-awesome-icon icon="envelope-circle-check"
                /></span>
                <span class="alert-text text-white">{{ confirmation }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: "EmailForm",
  components: {},
  data() {
    return {
      errors: [],
      confirmation: "",
      sent: false,
      submitStatus: null,
      form: {
        name: "",
        surname: "",
        email: "",
        message: "",
        checked: false,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      email: { required, email },
      message: { required, minLength: minLength(50) },
      checked: { required },
    },
  },
  methods: {
    sendForm: function () {
      this.sent = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        return new Promise((resolve, reject) => {
          axios({
            url: "/.netlify/functions/send_email",
            data: this.form,
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
          })
            .then((response) => {
              this.confirmation =
                "Message has been sent. We will contact you shortly.";
              this.submitStatus = "OK";
              console.log(response);
            })
            .catch((err) => {
              if (err.statusCode == 401) {
                resolve(err);
              } else {
                reject(err);
              }
            });
        });
      }
    },
  },
};
</script>
